// src/App.js
import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { AuthProvider, AuthContext } from './context/AuthContext';

import Header from './page/Header/Header';
import Footer from './page/Footer/Footer';
import LoginWithFooter from './page/login/Login';
import FirstLogin from './page/login/FirstLogin';
import Dashboard from './page/dashboard/dashboard';
import ManageDashboards from './page/managedashboard/ManageDashboards';
import Users from './page/users/Users';
import ImportCSV from './page/users/ImportCSVModal';
import EditUser from './page/users/EditUserModal';
import WelcomePage from './page/login/WelcomePage'; // Nova página de boas-vindas

import AccessDeniedWithFooter from './page/AccessDenied/AccessDenied';
import PageNotFoundWithFooter from './page/PageNotFound/PageNotFound';
import loadingGif from './assets/Loading.gif';

// Função de rota protegida
const PrivateRoute = ({ children, requiredAccessLevel = 0 }) => {
  const { isAuthenticated, loading, user } = useContext(AuthContext);
  const location = useLocation();

  // Permitir que a rota "/welcome" seja acessada com o token nos parâmetros da URL
  const isWelcomeWithToken = location.pathname === "/welcome" && location.search.includes('token=');

  if (loading) {
    return (
      <div style={{ textAlign: 'center', color: 'var(--text-color)' }}>
        <img src={loadingGif} alt="Carregando..." />
        <p>Carregando dados...</p>
      </div>
    );
  }

  // Permitir o acesso à página de boas-vindas quando houver um token
  if (isWelcomeWithToken) {
    return children;
  }

  // Redirecionar para o login se o usuário não estiver autenticado e não estiver na página de login
  if (!isAuthenticated && location.pathname !== "/" && location.pathname !== "/login") {
    return <Navigate to="/" />;
  }

  // Verificar o nível de acesso para rotas protegidas
  if (requiredAccessLevel > 0 && user?.access_level < requiredAccessLevel) {
    return <Navigate to="/access-denied" />;
  }

  return children;
};

// Layout do aplicativo
function Layout() {
  const location = useLocation();
  const { isAuthenticated, user } = useContext(AuthContext);

  const isRootPath = location.pathname === '/';
  const isFirstLoginPath = location.pathname === '/firstLogin';
  const isWelcomePath = location.pathname === '/welcome';

  if (isRootPath && isAuthenticated) {
    if (user?.firstLogin) {
      return <Navigate to="/firstLogin" />;
    }
    return <Navigate to="/dashboard" />;
  }

  return (
    <>
      {!isRootPath && !isFirstLoginPath && !isWelcomePath && <Header />}
      <div className="main-content">
        <Routes>
          <Route path="/" element={<LoginWithFooter />} />
          <Route
            path="/login"
            element={<LoginWithFooter />}
          />
          <Route
            path="/welcome"
            element={<WelcomePage />}
          />
          <Route
            path="/firstLogin"
            element={
              <PrivateRoute>
                <FirstLogin />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/manage-dashboards"
            element={
              <PrivateRoute requiredAccessLevel={1}>
                <ManageDashboards />
              </PrivateRoute>
            }
          />
          <Route
            path="/users"
            element={
              <PrivateRoute requiredAccessLevel={1}>
                <Users />
              </PrivateRoute>
            }
          />
          <Route
            path="/import-csv"
            element={
              <PrivateRoute requiredAccessLevel={1}>
                <ImportCSV />
              </PrivateRoute>
            }
          />
          <Route
            path="/edit-user"
            element={
              <PrivateRoute requiredAccessLevel={1}>
                <EditUser />
              </PrivateRoute>
            }
          />
          <Route path="/access-denied" element={<AccessDeniedWithFooter />} />
          <Route path="*" element={<PageNotFoundWithFooter />} />
        </Routes>
      </div>
      {!isRootPath && !isFirstLoginPath && !isWelcomePath && <Footer />}
    </>
  );
}

// Componente principal App
function App() {
  return (
    <Router>
      <AuthProvider>
        <Layout />
      </AuthProvider>
    </Router>
  );
}

export default App;
