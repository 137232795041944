import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import ApiUsersModal from './ApiUsersModal';
import ChangePasswordModal from './ChangePasswordModal';
import ManageUserModal from './ManageUserModal';
import '../../App.css';

const Header = ({ isDashboardPage = false }) => {
    const { token, logout, userEmail, user, loading } = useContext(AuthContext);
    const navigate = useNavigate();

    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const [showApiUsersModal, setShowApiUsersModal] = useState(false);
    const [showManageUserModal, setShowManageUserModal] = useState(false); // Estado para controlar o modal de gestão de usuário

    useEffect(() => {
        const savedTheme = localStorage.getItem('theme') || 'light';
        document.body.setAttribute('data-theme', savedTheme);
    }, []);

    useEffect(() => {
        if (!loading && !token) {
            navigate('/');
        }
    }, [loading, token, navigate]);

    useEffect(() => {
        if (user?.first_login) {
            setShowChangePasswordModal(true);
        }
    }, [user]);

    const handleLogout = () => {
        logout();
        navigate('/');
    };

    const toggleTheme = (newTheme) => {
        document.body.setAttribute('data-theme', newTheme);
        localStorage.setItem('theme', newTheme);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <header className="header d-flex justify-content-between align-items-center p-3">
            <div className="d-flex align-items-center">
                <img src="/logo-cliente.png" alt="Logo Cliente" className="header-logo" />
                {!isDashboardPage && (
                    <nav className="nav-links">
                        <Link to="/dashboard">Painel</Link>
                    </nav>
                )}
            </div>
            <div className="right-side">
                {userEmail ? (
                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic" className="user-dropdown-toggle">
                            <i className="bi bi-person-circle me-2"></i>
                            {userEmail || 'Carregando...'}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {user?.access_level === 1 && (
                                <>
                                    <Dropdown.Item as={Link} to="/manage-dashboards">
                                        Gestão de Dashboard
                                    </Dropdown.Item>
                                    <Dropdown.Item as={Link} to="/users">
                                        Gestão de Usuários
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => setShowManageUserModal(true)}>
                                        Gestão de Credenciais
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => setShowApiUsersModal(true)}>
                                        <i className="bi bi-lock me-2"></i>
                                        API de Usuários
                                    </Dropdown.Item>
                                </>
                            )}
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={() => setShowChangePasswordModal(true)}>
                                <i className="bi bi-lock me-2"></i>
                                Trocar senha
                            </Dropdown.Item>
                            <Dropdown.Item onClick={handleLogout}>
                                <i className="bi bi-box-arrow-right me-2"></i>
                                Logout
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={() => toggleTheme('light')}>
                                <i className="bi bi-brightness-high me-2"></i>
                                Light Mode
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => toggleTheme('dark')}>
                                <i className="bi bi-moon me-2"></i>
                                Dark Mode
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => toggleTheme('daltonic')}>
                                <i className="bi bi-palette me-2"></i>
                                Daltonic Mode
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => toggleTheme('green-theme')}>
                                <i className="bi bi-leaf me-2"></i>
                                Green Theme
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => toggleTheme('brown-theme')}>
                                <i className="bi bi-tree me-2"></i>
                                Brown Theme
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => toggleTheme('blue-grey-theme')}>
                                <i className="bi bi-cloud me-2"></i>
                                Blue-Grey Theme
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                ) : (
                    <div>Carregando dados do usuário...</div>
                )}
            </div>
            <ChangePasswordModal
                show={showChangePasswordModal}
                onClose={() => {
                    if (user?.first_login) {
                        alert('Para sua segurança, é necessário que você troque a senha.');
                        setShowChangePasswordModal(true);
                    } else {
                        setShowChangePasswordModal(false);
                    }
                }}
                userId={user?.id}
                forceChangePassword={user?.first_login}
            />
            <ApiUsersModal
                show={showApiUsersModal}
                onHide={() => setShowApiUsersModal(false)}
            />
            <ManageUserModal
                show={showManageUserModal}
                onHide={() => setShowManageUserModal(false)}
            />
        </header>
    );
};

export default Header;
