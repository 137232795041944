import React, { useState, useEffect } from 'react';
import { Button, Alert, Spinner, Modal, Form } from 'react-bootstrap';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup'; // Adicionando Yup para validação de formulário
import '../../App.css'; // Certifique-se de que esse arquivo CSS existe e está correto

const ConfigManagement = () => {
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [userInfo, setUserInfo] = useState({ username: '', senhaPadrao: '', licenseLimit: 0 });
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');

    const apiUrl = process.env.REACT_APP_API_URL ; // Verifica o valor padrão caso a variável não esteja definida

    // Função para buscar as credenciais do usuário
    const fetchCredentials = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api-dashflow/credentials`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('auth-token')}`, // Token de autenticação
                },
            });

            if (response.data) {
                setUserInfo({
                    senhaPadrao: response.data.password || 'Senha não definida',
                    licenseLimit: response.data.LICENSE_LIMIT || 0, // Mostrando LICENSE_LIMIT
                });
            } else {
                setError('Nenhuma informação de credencial encontrada.');
            }
        } catch (error) {
            console.error('Erro ao buscar informações das credenciais:', error);
            setError('Falha ao buscar informações das credenciais.');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        // Busca as credenciais quando o componente for montado
        fetchCredentials();
    }, []);

    const handleClosePasswordModal = () => {
        setShowPasswordModal(false);
    };

    const handleShowPasswordModal = () => {
        setShowPasswordModal(true);
    };

    return (
        <div className="config-management">


            {isLoading ? (
                <div className="d-flex justify-content-center align-items-center">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Carregando...</span>
                    </Spinner>
                </div>
            ) : (
                <>
                    {error && <Alert variant="danger">{error}</Alert>}

                    <div className="user-info">
                        <p><strong>Senha Padrão:</strong> {userInfo.senhaPadrao}</p>
                        <p><strong>Quantidade de Licenças Contratadas:</strong> {userInfo.licenseLimit}</p> {/* Mostrando LICENSE_LIMIT */}
                    </div>

                    <Button variant="primary" onClick={handleShowPasswordModal}>
                        Alterar Senha
                    </Button>

                    {/* Modal para alterar a senha */}
                    <ChangePasswordModal
                        show={showPasswordModal}
                        onClose={handleClosePasswordModal}
                        userId={1} // Assumindo que o ID do usuário está disponível aqui
                        forceChangePassword={false} // Se você quiser forçar a troca de senha, ajuste conforme necessário
                    />
                </>
            )}
        </div>
    );
};

// Modal para troca de senha
const ChangePasswordModal = ({ show, onClose, userId, forceChangePassword }) => {

    const validationSchema = Yup.object().shape({
        newPassword: Yup.string()
            .min(8, 'A senha deve ter no mínimo 8 caracteres.')
            .matches(/[a-z]/, 'A senha deve conter pelo menos uma letra minúscula.')
            .matches(/[A-Z]/, 'A senha deve conter pelo menos uma letra maiúscula.')
            .matches(/\d/, 'A senha deve conter pelo menos um número.')
            .matches(/[@#$!%*?&]/, 'A senha deve conter pelo menos um caractere especial (@,#, $, !, %, *, ?, &).')
            .required('Nova senha é obrigatória.'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'A nova senha e a confirmação não coincidem.')
            .required('A confirmação da nova senha é obrigatória.'),
    });

    useEffect(() => {
        if (forceChangePassword && !show) {
            setTimeout(() => onClose(), 100);
        }
    }, [show, forceChangePassword, onClose]);

    const handlePasswordChange = async (values, { setSubmitting, setStatus }) => {
        setStatus({ error: null, success: null });

        try {
            const token = localStorage.getItem('auth-token'); // Pegando o token do localStorage

            if (!token) {
                setStatus({ error: 'Token não encontrado. Por favor, faça login novamente.' });
                return;
            }

            // Fazendo a requisição POST para alterar a senha
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api-dashflow/credentials`, {
                userId,
                newPassword: values.newPassword,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`, // Enviando o token na requisição
                },
            });

            if (response.status === 200) {
                setStatus({ success: 'Senha alterada com sucesso.' });
                if (forceChangePassword) {
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                }
            } else {
                setStatus({ error: response.data.message || 'Erro ao alterar a senha.' });
            }
        } catch (error) {
            setStatus({
                error: error.response?.data?.message || 'Erro ao alterar a senha.',
            });
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Modal show={show} onHide={forceChangePassword ? null : onClose}>
            <Modal.Header closeButton={!forceChangePassword}>
                <Modal.Title>Trocar Senha</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={{ newPassword: '', confirmPassword: '' }}
                    validationSchema={validationSchema}
                    onSubmit={handlePasswordChange}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        status,
                    }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            {/* Mostrando mensagens de erro ou sucesso */}
                            {status?.error && <Alert variant="danger">{status.error}</Alert>}
                            {status?.success && <Alert variant="success">{status.success}</Alert>}
                            
                            {/* Campo para a nova senha */}
                            <Form.Group controlId="newPassword" className="mt-3">
                                <Form.Label>Nova Senha</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="newPassword"
                                    placeholder="Digite sua nova senha"
                                    value={values.newPassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched.newPassword && !!errors.newPassword}
                                    autoComplete="new-password"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.newPassword}
                                </Form.Control.Feedback>
                                <Form.Text className="text-muted">
                                    A senha deve ter no mínimo 8 caracteres, incluindo pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial.
                                </Form.Text>
                            </Form.Group>

                            {/* Campo para confirmação da nova senha */}
                            <Form.Group controlId="confirmPassword" className="mt-3">
                                <Form.Label>Confirme a Nova Senha</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="confirmPassword"
                                    placeholder="Confirme sua nova senha"
                                    value={values.confirmPassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isInvalid={touched.confirmPassword && !!errors.confirmPassword}
                                    autoComplete="new-password"
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.confirmPassword}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Button variant="primary" type="submit" disabled={isSubmitting} className="mt-4">
                                {isSubmitting ? 'Alterando...' : 'Alterar Senha'}
                            </Button>
                        </Form>
                    )}
                </Formik>

                {/* Mensagem adicional se for obrigatório trocar a senha */}
                {forceChangePassword && (
                    <Alert variant="warning" className="mt-3">
                        Para sua segurança, é necessário que você troque a senha.
                    </Alert>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default ConfigManagement;
