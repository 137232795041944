import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import * as powerbi from 'powerbi-client';
import { AuthContext } from '../../context/AuthContext';
import { Button, Dropdown, ButtonGroup } from 'react-bootstrap';
import { BsFullscreen, BsPrinter, BsQuestionCircle, BsFilter } from 'react-icons/bs';
import loadingGif from '../../assets/Loading.gif';

const Dashboards = () => {
  const { user } = useContext(AuthContext);
  const reportContainerRef = useRef(null);
  const qnaContainerRef = useRef(null); // Ref para o Q&A contêiner
  const [dashboards, setDashboards] = useState([]);
  const [filteredDashboard, setFilteredDashboard] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [selectedGrupo, setSelectedGrupo] = useState('');
  const [selectedDashboard, setSelectedDashboard] = useState('');
  const [loadingDashboard, setLoadingDashboard] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [embedToken, setEmbedToken] = useState(null); // Estado para armazenar o token
  const [isQeAEnabled, setIsQeAEnabled] = useState(false); // Estado para ativar o modo QeA

  // Função para renderizar o Q&A
  const renderQnA = (qnaEmbedToken, datasetId) => {
    const models = powerbi.models;
    const viewMode = models.QnaMode.Interactive;

    const config = {
      type: 'qna',
      tokenType: models.TokenType.Embed,
      accessToken: qnaEmbedToken,
      embedUrl: `https://app.powerbi.com/qnaEmbed?datasetId=${datasetId}`,
      datasetIds: [datasetId],
      viewMode: viewMode,
      question: '' // Se quiser adicionar uma pergunta predefinida
    };

    const qnaContainer = qnaContainerRef.current;
    
    // Inicialize o serviço Power BI
    const powerbiService = new powerbi.service.Service(
      powerbi.factories.hpmFactory,
      powerbi.factories.wpmpFactory,
      powerbi.factories.routerFactory
    );

    // Embutir o Q&A no contêiner
    powerbiService.embed(qnaContainer, config);
  };

  // Fetch Dashboards
  useEffect(() => {
    const fetchDashboards = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api-dashflow/dashboards`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setDashboards(response.data);
        setFilteredDashboard(response.data[0]); // Seleciona o primeiro dashboard por padrão
      } catch (error) {
        setError('Erro ao carregar os dados');
      } finally {
        setLoading(false);
      }
    };
    fetchDashboards();
  }, [user]);

  // Fetch Q&A Embed Token quando o modo QeA estiver ativado
  useEffect(() => {
    const fetchQnAEmbedToken = async () => {
      if (!filteredDashboard || !isQeAEnabled) return;

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api-dashflow/powerbi/qnaEmbedInfo`,
          {
            params: {
              reportId: filteredDashboard.report_id,
              workspaceId: filteredDashboard.WorkspaceId,
              datasetId: filteredDashboard.dataset_id,
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );

        const { qnaEmbedToken, datasetId } = response.data;

        // Renderizar o Q&A com o token de embed e datasetId
        renderQnA(qnaEmbedToken, datasetId);
      } catch (error) {
        console.error('Erro ao obter token de Q&A:', error);
      }
    };

    if (isQeAEnabled) {
      fetchQnAEmbedToken();
    }
  }, [isQeAEnabled, filteredDashboard]);

  // Load selected dashboard
  useEffect(() => {
    const loadDashboard = async () => {
      if (!filteredDashboard || !reportContainerRef.current) return;

      // Verifica se o token já existe e se ainda é válido
      if (embedToken && embedToken.expiration > Date.now()) {
        return; // Token ainda válido, não recarrega o dashboard
      }

      setLoadingDashboard(true);
      setErrorMessage(null);

      try {
        const workspaceId = Array.isArray(filteredDashboard.WorkspaceId)
          ? filteredDashboard.WorkspaceId[0]
          : filteredDashboard.WorkspaceId;

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api-dashflow/powerbi/reportEmbedInfo`,
          {
            params: {
              reportId: filteredDashboard.report_id,
              workspaceId: workspaceId,
              cluster: filteredDashboard.cluster,
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );

        const { accessToken, expiration } = response.data;

        // Atualiza o token e evita loops
        setEmbedToken({ token: accessToken, expiration: new Date(expiration).getTime() });

        const embedConfiguration = {
          type: 'report',
          id: filteredDashboard.report_id,
          embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${filteredDashboard.report_id}&groupId=${workspaceId}`,
          accessToken: accessToken,
          tokenType: powerbi.models.TokenType.Embed,
          settings: {
            panes: { filters: { visible: false }, pageNavigation: { visible: true } },
          },
        };

        const reportContainer = reportContainerRef.current;
        if (reportContainer) {
          const powerbiService = new powerbi.service.Service(
            powerbi.factories.hpmFactory,
            powerbi.factories.wpmpFactory,
            powerbi.factories.routerFactory
          );
          powerbiService.reset(reportContainer);
          powerbiService.embed(reportContainer, embedConfiguration);
        }

        setLoadingDashboard(false);
      } catch (error) {
        setErrorMessage('Erro ao carregar o dashboard.');
        setLoadingDashboard(false);
      }
    };

    if (filteredDashboard && !isQeAEnabled) {
      loadDashboard(); // Carrega o dashboard apenas se o modo QeA não estiver ativado
    }
  }, [filteredDashboard, isQeAEnabled]);

  // Lógica para ativar/desativar o modo QeA
  const handleToggleQeA = () => {
    setIsQeAEnabled(!isQeAEnabled);
  };

  const handleFullScreen = () => {
    const reportContainer = reportContainerRef.current;
    if (reportContainer.requestFullscreen) {
      reportContainer.requestFullscreen();
    } else if (reportContainer.webkitRequestFullscreen) {
      reportContainer.webkitRequestFullscreen();
    } else if (reportContainer.msRequestFullscreen) {
      reportContainer.msRequestFullscreen();
    } else if (reportContainer.mozRequestFullScreen) {
      reportContainer.mozRequestFullScreen();
    }
  };

  const handlePrint = () => {
    window.print();
  };

  const toggleFilter = () => setIsFilterVisible(!isFilterVisible);

  const gruposFiltrados = user.isAdmin
    ? [...new Set(dashboards.map((dashboard) => dashboard.grupo))]
    : [...new Set(dashboards.filter((d) => d.cluster === user.cluster).map((dashboard) => dashboard.grupo))];

  const dashboardsFiltrados = dashboards.filter(
    (dashboard) =>
      (user.isAdmin || dashboard.cluster === user.cluster) &&
      (selectedGrupo ? dashboard.grupo === selectedGrupo : true)
  );

  const handleDashboardSelect = (dashboard) => {
    setSelectedDashboard(dashboard.report_name);
    setFilteredDashboard(dashboard);
  };

  if (loading) {
    return (
      <div style={{ textAlign: 'center', color: 'var(--text-color)' }}>
        <img src={loadingGif} alt="Carregando..." />
        <p>Carregando dados...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div style={{ textAlign: 'center', color: 'var(--danger-color)' }}>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="Dashboards" style={{ minHeight: '100vh', backgroundColor: 'var(--background-color)', color: 'var(--text-color)' }}>
      <div>
        <div 
          className="ctn-button" 
          style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', marginTop: '20px' }}
        >
          <Button onClick={toggleFilter} title="Filtros" variant="outline-primary">
            <BsFilter />
          </Button>
          <Button 
            onClick={handleToggleQeA} 
            title={isQeAEnabled ? "Desativar QeA" : "Ativar QeA"} 
            variant={isQeAEnabled ? "secondary" : "outline-primary"}
          >
            <BsQuestionCircle />
          </Button>
          <Button onClick={handleFullScreen} title="Tela cheia" variant="outline-primary">
            <BsFullscreen />
          </Button>
          <Button onClick={handlePrint} title="Imprimir" variant="outline-primary">
            <BsPrinter />
          </Button>
        </div>

        {isFilterVisible && (
          <div className="filters">
            <Dropdown as={ButtonGroup}>
              <Dropdown.Toggle variant="primary" id="dropdown-basic">
                {selectedDashboard || 'Selecione um Grupo e Dashboard'}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {gruposFiltrados.map((grupo, index) => (
                  <Dropdown.Item
                    key={index}
                    as="div"
                    onMouseEnter={() => setSelectedGrupo(grupo)}
                    style={{ position: 'relative', padding: '10px', fontWeight: 'bold' }}
                  >
                    {grupo}
                    {selectedGrupo === grupo && (
                      <div
                        style={{
                          position: 'absolute',
                          top: '0',
                          left: '100%',
                          background: '#fff',
                          border: '1px solid rgba(0,0,0,.125)',
                          padding: '10px',
                          minWidth: '200px',
                          zIndex: '1000',
                          boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                        }}
                      >
                        {dashboardsFiltrados
                          .filter((dashboard) => dashboard.grupo === grupo)
                          .map((dashboard, index) => (
                            <div
                              key={index}
                              style={{
                                padding: '8px',
                                cursor: 'pointer',
                                color: selectedDashboard === dashboard.report_name ? '#007bff' : 'black',
                                background: selectedDashboard === dashboard.report_name ? '#f1f1f1' : 'transparent',
                              }}
                              onClick={() => handleDashboardSelect(dashboard)}
                              onMouseEnter={(e) => (e.target.style.background = '#e9ecef')}
                              onMouseLeave={(e) => (e.target.style.background = selectedDashboard === dashboard.report_name ? '#f1f1f1' : 'transparent')}
                            >
                              {dashboard.report_name}
                            </div>
                          ))}
                      </div>
                    )}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}

        {isQeAEnabled ? (
          <div style={{ backgroundColor: 'var(--background-color)', padding: '20px', borderRadius: '8px', textAlign: 'center' }}>
            <h2>Seja bem-vindo ao QeA</h2>

            <div id="qnaEmbedContainer" ref={qnaContainerRef} style={{ height: '600px' }}></div> {/* Contêiner para Q&A */}
          </div>
        ) : (
          filteredDashboard && (
            <div style={{ backgroundColor: 'var(--background-color)', padding: '20px', borderRadius: '8px' }}>
              {loadingDashboard && <p>Carregando dashboard...</p>}
              {errorMessage && <p>{errorMessage}</p>}
              <div id="reportContainer" ref={reportContainerRef} style={{ height: '600px' }}></div>
            </div>
          )
        )}

      </div>
    </div>
  );
};

export default Dashboards;
