// C:\Projeto\HNT\Frontend\src\page\Header\ManageUserModal.js

import React, { useState, useEffect } from 'react';
import { Form, Button, Alert, Modal } from 'react-bootstrap';
import axios from 'axios';
import PasswordManagement from './ConfigManagement.js';
import LoadingGif from '../../assets/Loading.gif';

const ManageUserModal = ({ show, onHide }) => {
  const [userInfo, setUserInfo] = useState({ senhaPadrao: '', username: '' });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const apiUrl = process.env.REACT_APP_API_URL;

  const fetchUserInfo = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${apiUrl}/api-dashflow/service-user-info`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setUserInfo({
        senhaPadrao: response.data.password || 'Senha não definida',
        username: response.data.username || '',
      });
    } catch (error) {
      setError('Falha ao buscar informações do usuário.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (show) fetchUserInfo();
  }, [show]);

  const handleLogin = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api-dashflow/sso-login`);
      const { loginUrl } = response.data;
      const loginWindow = window.open(loginUrl, '_blank');

      const handleMessage = async (event) => {
        if (event.data === 'ssoCompleted') {
          window.removeEventListener('message', handleMessage);
          await fetchUserInfo();
        }
      };

      window.addEventListener('message', handleMessage);
    } catch (error) {
      setError('Erro ao iniciar login com SSO.');
    }
  };

  const handleLogout = async () => {
    try {
      await axios.post(`${apiUrl}/api-dashflow/logout-service-user`, {}, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setUserInfo({ senhaPadrao: '', username: '' });
      localStorage.removeItem('sso_token');
    } catch (error) {
      setError('Falha ao fazer logout.');
    } finally {
      await fetchUserInfo();
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Gestão de Credenciais</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center">
            <img src={LoadingGif} alt="Carregando" style={{ width: '100%', maxWidth: '150px' }} />
          </div>
        ) : (
          <>
            {error && <Alert variant="danger">{error}</Alert>}
            <PasswordManagement
              apiUrl={apiUrl}
              token={localStorage.getItem('token')}
              userInfo={userInfo}
              setUserInfo={setUserInfo}
            />
            <Form.Group controlId="powerbiAccount" className="mt-3">
              <Form.Label>Conta Power BI</Form.Label>
              <div className="d-flex align-items-center">
                <Form.Control
                  type="text"
                  readOnly
                  value={userInfo.username || 'Aguardando login'}
                  size="sm"
                />
                <Button
                  variant={userInfo.username ? 'danger' : 'secondary'}
                  onClick={userInfo.username ? handleLogout : handleLogin}
                  size="sm"
                  className="ms-2"
                >
                  {userInfo.username ? 'Logout' : 'Login'}
                </Button>
              </div>
            </Form.Group>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onHide}>Salvar</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ManageUserModal;
